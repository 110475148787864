// ** Auth Endpoints
const url = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : process.env.REACT_APP_BASE_URL_DEV

export default {
  loginEndpoint: `${url}/login`,
  forgotPassword: `${url}/forgot-password`,
  resetPassword: `${url}/reset-password`,
  fleetAddComp: `${url}/fleet/company`,
  fleetAddVehicle: `${url}/fleet/vehicle`,
  addUser: `${url}/user`,
  addRule: `${url}/rule`,
  addScheme: `${url}/scheme`,
  driverManagement: `${url}/driver-management`,
  config: `${url}/config`,
  dashboard: `${url}/index`,
  getInvoices: `${url}/get-invoices`,
  grtInvoicesSummary: `${url}/get-invoice-summary`,


  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${url}/auth/refresh-tokens`,
  logoutEndpoint: '/jwt/logout',

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}

